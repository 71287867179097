<template>
  <main>
    <package-header title="Edit Application" subTitle="12764 Smith"></package-header>

    <div class="container-fluid">
      <div class="row">
        <div class="col">

          <div class="mb-3 border-bottom pb-3">
            <!-- Back to applications link -->
            <router-link class="mr-2" to="/pages/applications">
              <svg class="icon icon-arrow_cta_back mr-sm-1"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_cta_back"></use></svg>
              <span class="d-none d-sm-inline-block">Return to Applications</span>
              <span class="d-sm-none">Back</span>
            </router-link>

            <!-- Change instrument dropdown -->
            <a href="javascript:void(0)" class="btn btn-md btn-secondary dropdown-toggle d-sm-inline-block" id="instrument" data-toggle="dropdown" aria-controls="instrument-contents" aria-expanded="false">
              Go To Application
              <span class="caret"></span>
            </a>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" href="javascript:void(0)">01 - Transfer Caso <span class="badge badge-success">E-signed</span></a>
              <a class="dropdown-item font-weight-bold" href="javascript:void(0)">02 - A1 release</a>
              <a class="dropdown-item" href="javascript:void(0)">03 - Mortgage Caso</a>
              <a class="dropdown-item" href="javascript:void(0)">04 - A2 release</a>
              <a class="dropdown-item" href="javascript:void(0)">05 - Application with longer name</a>
            </div>
          </div>

          <!-- Package title and actions -->
          <div class="d-md-flex align-items-start justify-content-between">
            <div>
              <h2 class="page-title mr-2">
                A1 release
              </h2>

              <ul class="list-inline mb-1">
                <li class="list-inline-item">
                  <small><span class="text-muted text-small">Status:</span> <strong>Drafted</strong></small>
                </li>
                <li class="list-inline-item">
                  <small><span class="text-muted">Modified:</span> <strong>Sep 22, 2018 at 5:33 PM</strong></small>
                </li>
              </ul>
            </div>

            <!-- Drawer toggle and delete package -->
            <div class="d-flex justify-content-between">
              <button class="btn btn-md btn-secondary d-md-none mb-2" type="button" data-toggle="collapse" data-target="#application-nav" aria-expanded="false" aria-controls="application-nav">
                <svg class="icon icon-more"><use xlink:href="/icons/symbol-defs.svg#icon-more"></use></svg>
                <svg class="icon icon-close"><use xlink:href="/icons/symbol-defs.svg#icon-close"></use></svg>
                Navigate Application
              </button>
              <button type="button" class="btn btn-md btn-danger mb-2">
                <svg class="icon icon-delete d-sm-none"><use xlink:href="/icons/symbol-defs.svg#icon-delete"></use></svg>
                <span class="d-none d-sm-block">Delete Application</span>
              </button>
            </div>
          </div>

          <!-- Main package content -->
          <div class="row mt-md-3">

            <!-- Step wizard -->
            <div class="col-md-3 mb-2 mb-md-4">
              <div class="collapse collapse-md" id="application-nav">
                <div class="step-wizard">
                  <div class="step">
                    <router-link class="step-link" to="/pages/edit-application/questionnaire">Transferees</router-link>
                    <div class="step">
                      <router-link class="step-link" to="/pages/edit-application/part-a-individual">{Individual Transferee Name}</router-link>
                    </div>
                    <div class="step active">
                      <router-link class="step-link" to="/pages/edit-application/part-a-corporation">{Corporate Transferee Name}</router-link>
                    </div>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Contact Info</a>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Transferors</a>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Property Description</a>
                  </div>
                  <div class="step">
                    <router-link class="step-link" to="/pages/edit-application/part-e-terms">Terms</router-link>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Gross Purchase Price</a>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Additional Info</a>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Principal Residence Info</a>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Tax Calculation</a>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">First Time Home Buyers</a>
                  </div>
                  <div class="step">
                    <router-link class="step-link" to="/pages/edit-application/certification">Certification</router-link>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Attachments</a>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Validation</a>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Presentation Copy</a>
                  </div>
                </div>
              </div>
            </div>

            <!-- Data entry form -->
            <div class="col-md-9">

              <div class="d-flex justify-content-between align-items-center mb-2">
                <h2 class="mb-0">{Corporate Transferee Name}</h2>
                <button class="btn btn-md btn-danger">Delete</button>
              </div>

              <!-- Corporation form -->
              <form @submit.prevent>
                <div class="mb-4 p-2 p-lg-4 bg-medium">
                  <h3>Transferee Info</h3>

                  <div class="card">
                    <div class="card-body">
                      Transferee type: <strong>Corporation</strong><br>
                      Exemption Claimed: <strong>18 - Family Farm Corporation</strong>
                    </div>
                  </div>

                  <fieldset class="mt-4 border-bottom">
                    <div class="form-group">
                      <label for="legalCorporationName">Legal Corporation Name</label>
                      <input type="text" class="form-control" id="legalCorporationName">
                    </div>

                    <div class="form-group">
                      <label for="percentInterest">Percentage Interest Acquired</label>
                      <div class="input-group">
                        <input type="text" class="form-control col-sm-4 col-md-3 border-right-0" id="inlineFormInputGroup">
                        <div class="input-group-append">
                          <div class="input-group-text">%</div>
                        </div>
                      </div>
                    </div>

                    <div class="form-group">
                      <label for="businessNumber">Business Number
                        <a href="#" data-toggle="popover" data-placement="top" data-html="true" data-content="Business Number (BN) is a 9 digit number that is unique to your business and that is used when dealing with federal, provincial, or local governments. Go to the <a class='link-external' href='https://www.canada.ca/en/revenue-agency/services/tax/businesses/topics/registering-your-business/register.html' target='_blank' rel='nofollow noopener'>Government of Canada website</a> for help on obtaining a business number." v-popover>
                          <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
                        </a>
                      </label>
                      <input type="text" class="form-control col-md-3" id="businessNumber">
                    </div>
                  </fieldset>

                  <fieldset class=" border-bottom">
                    <legend>Address</legend>
                    <div class="form-row">
                      <div class="form-group col-md-6">
                        <label for="address1">Address 1</label>
                        <input type="text" class="form-control" id="address1">
                        <small class="form-text text-muted">Street No./Name</small>
                      </div>
                      <div class="form-group col-md-6">
                        <label for="address2">Address 2 <small>(optional)</small></label>
                        <input type="text" class="form-control" id="address2">
                        <small class="form-text text-muted">Apt. No., PO Box, RR</small>
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="form-group col-md-6">
                        <label for="city">City</label>
                        <input type="text" class="form-control" id="city">
                      </div>
                      <div class="form-group col-md-6">
                        <label for="country">Country</label>
                        <select class="form-control" id="country">
                          <option>Canada</option>
                          <option>United Kingdom</option>
                          <option>United States</option>
                          <option>Mexico</option>
                          <option>Other</option>
                        </select>
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="form-group col-md-6">
                        <label for="province">Province</label>
                        <select class="form-control" id="province">
                          <option value="AB">Alberta</option>
                          <option value="BC" selected>British Columbia</option>
                          <option value="MB">Manitoba</option>
                          <option value="NB">New Brunswick</option>
                          <option value="NL">Newfoundland and Labrador</option>
                          <option value="NT">Northwest Territory</option>
                          <option value="NS">Nova Scotia</option>
                          <option value="NU">Nunavut</option>
                          <option value="ON">Ontario</option>
                          <option value="PE">Prince Edward Island</option>
                          <option value="QC">Quebec</option>
                          <option value="SK">Saskatchewan</option>
                          <option value="YT">Yukon Territory</option>
                        </select>
                      </div>
                      <div class="form-group col-md-6">
                        <label for="frmZipS">
                          Postal Code
                          <a href="#" data-toggle="popover" data-placement="top" data-content="If you do not know the postal code please use UNK for Canada or NCA otherwise." v-popover>
                            <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
                          </a>
                        </label>
                        <input class="form-control col-md-6" name="ship-zip" id="frmZipS" autocomplete="shipping postal-code">
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="form-group col-md-6">
                        <label for="phone">
                          Phone Number
                          <a href="#" data-toggle="popover" data-placement="top" data-content="If calling from outside of North America, enter your international country code." v-popover>
                            <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
                          </a>
                        </label>
                        <input class="form-control" type="tel" name="phone" id="phone" autocomplete="tel-national">
                      </div>
                      <div class="form-group col-md-6">
                        <label for="email">Email <small>(optional)</small></label>
                        <input class="form-control" type="email" name="email" id="email" autocapitalize="off" autocorrect="off" autocomplete="email">
                      </div>
                    </div>
                  </fieldset>

                  <fieldset class="mb-0">
                    <label class="mt-1">Is the transferee holding the property on behalf of a partnership?</label>
                    <div class="form-group mt-1">
                      <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="behalfOfPartnership" name="behalfOfPartnership" class="custom-control-input">
                        <label class="custom-control-label" for="behalfOfPartnership">Yes</label>
                      </div>
                      <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="behalfOfPartnership2" name="behalfOfPartnership" class="custom-control-input">
                        <label class="custom-control-label" for="behalfOfPartnership2">No</label>
                      </div>
                    </div>

                    <label class="mt-1">Is the transferee a trustee?</label>
                    <div class="form-group mt-1">
                      <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="trustee" name="trustee" class="custom-control-input" @click="showTrust = true">
                        <label class="custom-control-label" for="trustee">Yes</label>
                      </div>
                      <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="trustee2" name="trustee" class="custom-control-input" @click="showTrust = false">
                        <label class="custom-control-label" for="trustee2">No</label>
                      </div>
                    </div>

                    <label class="mt-1">
                      Is this a public company?
                      <a href="#" data-toggle="popover" data-placement="top" data-html="true" data-content="If a transferee is a public company as defined in section 1 (1) [definitions] of the <a class='link-external' href='http://www.bclaws.ca/civix/document/id/complete/statreg/02057' target='_blank' rel='nofollow noopener'>Business Corporations Act</a>, a return filed under section 2 of the Act must state the name of that public company." v-popover>
                        <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
                      </a>
                    </label>
                    <div class="form-group mt-1">
                      <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="publicCompany" name="publicCompany" class="custom-control-input">
                        <label class="custom-control-label" for="publicCompany">Yes</label>
                      </div>
                      <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="publicCompany2" name="publicCompany" class="custom-control-input">
                        <label class="custom-control-label" for="publicCompany2">No</label>
                      </div>
                    </div>

                    <div class="form-group">
                      <label for="publicCompanyName">Public company name (if different from legal name)</label>
                      <input type="text" class="form-control" id="publicCompanyName">
                    </div>

                    <label class="mt-1">
                      Is the corporation excluded from the additional information disclosure?
                      <a href="#" data-toggle="popover" data-placement="top" data-html="true" data-content="[Contextual help describing who is excluded from disclosing additional information. Possible link to be included]" v-popover>
                        <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
                      </a>
                    </label>
                    <div class="form-group mt-1">
                      <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="corporationExcluded" name="corporationExcluded" class="custom-control-input" @click="excludedFromDisclosure = true">
                        <label class="custom-control-label" for="corporationExcluded">Yes</label>
                      </div>
                      <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="corporationExcluded2" name="corporationExcluded" class="custom-control-input" @click="excludedFromDisclosure = false">
                        <label class="custom-control-label" for="corporationExcluded2">No</label>
                      </div>
                    </div>

                    <div class="form-group" v-if="excludedFromDisclosure">
                      <label for="exclusionType">Exclusion Type</label>
                      <select class="form-control col-sm-9 col-md-8 col-lg-6">
                        <option>Select the type of exclusion that applies:</option>
                        <option>3(a) – Government Reporting Entity</option>
                        <option>3(b) – Government Body</option>
                        <option>3(c) – Local Public Body</option>
                        <option>3(d) – Public Body</option>
                        <option>3(e) – Municipality / Regional District</option>
                        <option>3(f) – Public Company</option>
                        <option>3(g) – Enactment</option>
                        <option>3(h) – Water Utility</option>
                        <option>3(i) – Airport Authority</option>
                        <option>3(j) – Port Authority</option>
                        <option>3(k) – Independent School</option>
                        <option>3(l) – Strata Corporation</option>
                        <option>3(m) – Savings Institution</option>
                        <option>3(n) – Insurance Company</option>
                        <option>3(o) – Trust Company</option>
                        <option>3(p) – Pension Fund Society</option>
                        <option>5(a)(b) – Indigenous Nation</option>
                      </select>
                    </div>

                    <label class="mt-1">
                      Does the corporation have any corporate interest holders?
                      <a href="#" data-toggle="popover" data-placement="top" data-html="true" data-content="A corporate interest holder is an individual who has:
                          <ul>
                            <li>beneficial and legal ownership in land, or</li>
                            <li>a significant interest or control or exerciseable rights in the landowning corporation (25% or more)</li>
                          </ul>" v-popover>
                        <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
                      </a>
                    </label>
                    <div class="form-group mt-1">
                      <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="corprateInterest" name="corprateInterest" class="custom-control-input" @click="hasCorporateInterestHolders = true">
                        <label class="custom-control-label" for="corprateInterest">Yes</label>
                      </div>
                      <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="corprateInterest2" name="corprateInterest" class="custom-control-input" @click="hasCorporateInterestHolders = false">
                        <label class="custom-control-label" for="corprateInterest2">No</label>
                      </div>
                    </div>

                    <label class="mt-1">
                      Is the transferee a "foreign corporation"?
                      <a href="#" data-toggle="popover" data-placement="top" data-html="true" data-content="[Contextual help with definition of foreign corporation]" v-popover>
                        <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
                      </a>
                    </label>
                    <div class="form-group mt-1">
                      <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="foreignCorporation" name="foreignCorporation" class="custom-control-input">
                        <label class="custom-control-label" for="foreignCorporation">Yes</label>
                      </div>
                      <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="foreignCorporation2" name="foreignCorporation" class="custom-control-input">
                        <label class="custom-control-label" for="foreignCorporation2">No</label>
                      </div>
                    </div>

                    <div class="form-group">
                      <label for="province">Jurisdiction of Incorporation</label>
                      <select class="form-control col-md-6" id="province">
                        <option>Select jurisdiction</option>
                        <option value="AB">Alberta</option>
                        <option value="BC">British Columbia</option>
                        <option value="MB">Manitoba</option>
                        <option value="NB">New Brunswick</option>
                        <option value="NL">Newfoundland and Labrador</option>
                        <option value="NT">Northwest Territory</option>
                        <option value="NS">Nova Scotia</option>
                        <option value="NU">Nunavut</option>
                        <option value="ON">Ontario</option>
                        <option value="PE">Prince Edward Island</option>
                        <option value="QC">Quebec</option>
                        <option value="SK">Saskatchewan</option>
                        <option value="YT">Yukon Territory</option>
                      </select>
                    </div>

                    <label class="mt-1">
                      Are any shareholders of the corporation a "foreign entity"?
                      <a href="#" data-toggle="popover" data-placement="top" data-html="true" data-content="[Contextual help with definition of foreign entity]" v-popover>
                        <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
                      </a>
                    </label>
                    <div class="form-group mt-1">
                      <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="foreignEntity" name="foreignEntity" class="custom-control-input">
                        <label class="custom-control-label" for="foreignEntity">Yes</label>
                      </div>
                      <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="foreignEntity2" name="foreignEntity" class="custom-control-input">
                        <label class="custom-control-label" for="foreignEntity2">No</label>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </form>

              <div class="mb-4">
                <directors :sectionId="6792"></directors>
              </div>
              <div class="mb-4" v-if="!excludedFromDisclosure && hasCorporateInterestHolders">
                <corporate-interest-holders :index="1"></corporate-interest-holders>
              </div>
              <div class="mb-4" v-if="showTrust">
                <trust
                  :exemptFromDisclosure="trustExemptFromDisclosure" @onExemptFromDisclosure="trustExemptFromDisclosure = $event"
                ></trust>
              </div>
              <div class="mb-4" v-if="showTrust && trustExemptFromDisclosure === false">
                <settlors-beneficiaries :sectionId="2057"></settlors-beneficiaries>
              </div>

              <!-- Action buttons -->
              <div class="d-flex flex-column-reverse flex-sm-row justify-content-sm-between d-print-none">
                <div class="mt-2 mt-sm-0">
                  <button class="btn btn-secondary">Back</button>
                </div>
                <div class="d-flex justify-content-between">
                  <button class="btn btn-secondary mr-1">Save Progress</button>
                  <button class="btn btn-primary">Continue <svg class="icon icon-arrow_cta ml-1"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_cta"></use></svg></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Scroll to top -->
      <a href="#top" class="d-block float-right scroll-to-top">
        <svg class="icon icon-arrow_upward"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_upward"></use></svg>Back to Top
      </a>
    </div>

    <help-panel title="Applications"></help-panel>
  </main>
</template>

<script>
import Trust from './shared/Trust.vue'
import SettlorsBeneficiaries from './shared/SettlorsBeneficiaries.vue'
import PackageHeader from '../../../../components/PackageHeader.vue'
import PackageNavigation from '../../../../components/PackageNavigation.vue'
import HelpPanel from '../../../../components/HelpPanel.vue'
import DatePicker from '../../../../components/DatePicker'

export default {

  components: {
    'trust': Trust,
    'settlors-beneficiaries': SettlorsBeneficiaries,
    'package-header': PackageHeader,
    'package-navigation': PackageNavigation,
    'help-panel': HelpPanel,
  },

  data () {
    return {
      hasCorporateInterestHolders: false,
      showTrust: false,
      excludedFromDisclosure: false,
      trustExemptFromDisclosure: null
    }
  },
  methods: {

  }
}
</script>

